import { FormProvider, useForm } from 'react-hook-form';
import { Button, Heading, Layout, SimpleModal, Text } from '../../../components/core';
import { FormInput } from '../../../components/form';
import { ReferralErrorCode, useStudentHubReferralMutation } from '../../../graphQL';
import { getStylesheet } from '../../../styles';

type StudentHubReferralModalProps = {
  onClose: () => void;
  onReferralSubmit: () => void;
  orgName: string;
};

export const StudentReferralModal = ({
  onClose,
  onReferralSubmit,
  orgName,
}: StudentHubReferralModalProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      email: '',
    },
  });

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = formContext;

  const [submitReferral, { loading }] = useStudentHubReferralMutation({
    onCompleted: async ({ studentHubReferral }) => {
      const responseType = studentHubReferral?.__typename;

      if (responseType === 'ReferralError') {
        if (studentHubReferral.errorCode === ReferralErrorCode.InvalidOrgEmail) {
          formContext.setError('email', {
            type: ReferralErrorCode.InvalidOrgEmail,
            message: `Please enter a valid ${orgName} email address.`,
          });

          return;
        }

        if (studentHubReferral.errorCode === ReferralErrorCode.InvalidEmail) {
          formContext.setError('email', {
            type: ReferralErrorCode.InvalidEmail,
            message: `This is an invalid email address.`,
          });

          return;
        }

        formContext.setError('email', {
          type: 'error',
          message: 'An error occurred. Please try again.',
        });
        return;
      }

      onReferralSubmit();
    },
  });

  const submitForm = (): void => {
    clearErrors();

    void handleSubmit(async ({ email }) => {
      await submitReferral({
        variables: {
          email,
        },
      });
    })();
  };

  return (
    <SimpleModal {...styles.modal} size="lg" onClose={onClose}>
      <FormProvider {...formContext}>
        <Layout.VStack {...styles.contentWrapper} space={6}>
          <Layout.VStack {...styles.textContent} space={2}>
            <Heading.h2>Refer a friend or classmate</Heading.h2>

            <Text.paraSmall>
              Enter a friend or classmate’s {orgName} email address and we’ll send them a link to
              sign up.
            </Text.paraSmall>

            <FormInput
              autoCapitalize="none"
              control={control}
              error={errors.email}
              isDisabled={loading}
              isRequired
              name="email"
              label="Enter their email"
              onSubmitEditing={submitForm}
              placeholder="Email address"
            />
          </Layout.VStack>

          <Layout.HStack {...styles.buttonWrapper} space={3}>
            <Button.dangerMedium testID="button-referral-modal-cancel" onPress={onClose}>
              Cancel
            </Button.dangerMedium>

            <Button.primaryMedium testID="button-referral-modal-submit" onPress={submitForm}>
              Submit
            </Button.primaryMedium>
          </Layout.HStack>
        </Layout.VStack>
      </FormProvider>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  buttonWrapper: {
    justifyContent: 'flex-start',
    paddingX: 2,
  },

  contentWrapper: {
    paddingTop: 10,
  },

  modal: {
    borderRadius: 0,
  },

  textContent: {
    padding: 2,
  },
});
