import { defaultTo } from 'lodash';
import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Select } from '../core';
import { SelectProps } from '../core/Select';
import { FormErrorInput } from './FormErrorInput';
import { FormHelperText } from './FormHelperText';
import { FormLabel, FormLabelProps } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

export enum SelectErrorTypes {
  Validate = 'required', // from isRequired or rules.required
}

type FormSelectProps<TFieldValues extends FieldValues> = SelectProps<string> &
  UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> &
  Omit<FormLabelProps, 'inputId'> & {
    error?: FieldErrors<TFieldValues>[string];
    helperText?: string;
    hideLabel?: boolean;
    isRequired?: boolean;
  };

export function FormSelect<TFieldValues extends FieldValues>({
  control,
  error,
  name,
  onLayout,
  options,
  helperText = '',
  hideLabel = false,
  isRequired = false,
  label = '',
  necessityIndicator = false,
  rules = {},
  ...selectProps
}: FormSelectProps<TFieldValues>): JSX.Element {
  const finalRules = {
    ...rules,
    required: defaultTo(rules.required, {
      value: Boolean(isRequired),
      message: 'This field is required.',
    }),
  };

  const nativeId = name;
  const selectId = `${nativeId}-input`;

  const ariaLabel = label ? { accessibilityLabel: label } : {};

  return (
    <FormControl
      isInvalid={Boolean(error?.type)}
      isReadOnly
      nativeID={nativeId}
      onLayout={onLayout}
    >
      {!hideLabel && (
        <FormLabel
          inputId={selectId}
          label={label}
          necessityIndicator={necessityIndicator}
          isRequired={isRequired}
        />
      )}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, ref, value } }) => (
          <Select
            {...selectProps}
            {...ariaLabel}
            options={options}
            selectedValue={value}
            onValueChange={onChange}
            ref={ref}
          />
        )}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
