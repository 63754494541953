import React from 'react';
import { Button, Heading, Layout, Text } from '../../../components/core';
import { IconMessageCircle } from '../../../components/icons/IconMessageCircle';
import { IconMessageSquare } from '../../../components/icons/IconMessageSquare';
import { IconPhone } from '../../../components/icons/IconPhone';
import { HubConnectNowData } from '../../../graphQL';
import { useExternalNavigate } from '../../../routes';
import { getStylesheet } from '../../../styles';

type Props = {
  connectNowData: HubConnectNowData;
};

// When On Demand is unavailable, we show the crisis contact info from the entitlement
export const OtherContactMethods = ({ connectNowData }: Props): JSX.Element => {
  const externalNavigate = useExternalNavigate();

  const phone: string = connectNowData.crisisCallPhoneNumber;
  const sms: string = connectNowData.crisisSMS ?? '';
  const webchat: string = connectNowData.crisisWebChat ?? '';

  const getTitle = (): string => {
    if (sms && webchat) {
      return 'Phone, text, or webchat';
    } else if (sms) {
      return 'Phone or text';
    } else if (webchat) {
      return 'Phone or webchat';
    }

    return 'Phone';
  };

  return (
    <Layout.VStack {...styles.otherContact} space={2}>
      <Heading.h5>{getTitle()}</Heading.h5>
      <Layout.VStack space={4}>
        <Text.caption>Available 24/7</Text.caption>
        <Layout.VStack space={2}>
          <Button.secondaryMedium
            testID="button-connect-now-call"
            onPress={() => externalNavigate(`tel:${phone}`, { sameTab: true })}
            leftIcon={<IconPhone accessibilityHidden />}
          >
            {`Call ${phone}`}
          </Button.secondaryMedium>
          {sms && (
            <Button.secondaryMedium
              testID="button-connect-now-text"
              disabled
              leftIcon={<IconMessageSquare accessibilityHidden />}
            >
              {`Text ${sms}`}
            </Button.secondaryMedium>
          )}
          {webchat && (
            <Button.secondaryMedium
              testID="button-connect-now-chat"
              onPress={() => externalNavigate(webchat)}
              leftIcon={<IconMessageCircle accessibilityHidden />}
            >
              Chat via Website
            </Button.secondaryMedium>
          )}
        </Layout.VStack>
      </Layout.VStack>
    </Layout.VStack>
  );
};

const styles = getStylesheet({
  otherContact: {
    backgroundColor: 'white',
    borderRadius: 8,
    flex: 1,
    padding: 6,
    shadow: 'large',
  },
});
