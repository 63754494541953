import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout, Link, StatusFeedback } from '../../../components/core';
import { FormErrorSubmit, FormInputPassword } from '../../../components/form';
import { useSignUpMutation } from '../../../graphQL';
import { getRoute } from '../../../routes';
import { SignupErrorModal, SignupErrorType } from '../components/SignupErrorModal';
import { usePasswordRules } from '../hooks/usePasswordRules';

type SignupEmailFormProps = {
  onSuccess: () => void;
  token: string;
  tokenId: string;
};

type InvalidTokenError = 'InvalidToken' | 'TokenExpired' | undefined;

export const SignupPasswordForm = ({
  onSuccess,
  token,
  tokenId,
}: SignupEmailFormProps): JSX.Element => {
  const [signupErrorType, setSignupErrorType] = useState<SignupErrorType | undefined>();
  const [tokenExpiredError, setTokenExpiredError] = useState<InvalidTokenError>();
  const formContext = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      submit: undefined,
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const [signupUser, { loading }] = useSignUpMutation({
    onCompleted: signupResponse => {
      const responseType = signupResponse.signUp.typename;

      if (responseType === 'SignUpSuccess') {
        onSuccess();
        return;
      }

      if (responseType === 'SignUpError') {
        const { errorCode, message } = signupResponse.signUp;

        if (errorCode === 'UnknownPartner') {
          setSignupErrorType('unavailable');
        } else if (errorCode === 'EligibilityError') {
          setSignupErrorType('isIneligible');
        } else if (errorCode === 'CampusReferralRequired') {
          setSignupErrorType('refRequired');
        } else if (errorCode === 'InvalidPassword') {
          formContext.setError('password', { message, type: 'password' });
        } else {
          // Take them back to reset password to start the process over.
          setTokenExpiredError(errorCode);
        }
        return;
      }

      formContext.setError('submit', {
        type: 'unknown',
        message: 'An internal error occurred.',
      });
    },
    onError: authError => {
      formContext.setError('submit', authError);
    },
  });

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();
    setTokenExpiredError(undefined);
    setSignupErrorType(undefined);

    void handleSubmit(async ({ password }): Promise<void> => {
      await signupUser({ variables: { token, tokenId, password } });
    })();
  };

  const onErrorClose = (): void => {
    setSignupErrorType(undefined);
  };

  const passwordValue = watch('password');

  const { passwordRules, confirmPasswordRules } = usePasswordRules(passwordValue);

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.VStack space={3}>
          <FormInputPassword
            testID="signup-input-password"
            label="Enter your password"
            name="password"
            placeholder="Password"
            rules={passwordRules}
            autoFocus
            isRequired
            control={control}
            error={errors.password}
            onSubmitEditing={submitForm}
          />

          <FormInputPassword
            testID="signup-input-password-confirm"
            label="Confirm your password"
            name="passwordConfirm"
            placeholder="Confirm Password"
            rules={confirmPasswordRules}
            isRequired
            control={control}
            error={errors.passwordConfirm}
            onSubmitEditing={submitForm}
          />

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}

          {signupErrorType !== undefined && (
            <SignupErrorModal errorType={signupErrorType} onClose={onErrorClose} />
          )}

          {tokenExpiredError === 'TokenExpired' && (
            <FormErrorSubmit marginTop={3}>
              Signup link expired. Please go back to the{' '}
              <Link.para to={getRoute('signup', {})}>signup</Link.para> page to generate a new link.
            </FormErrorSubmit>
          )}

          {tokenExpiredError === 'InvalidToken' && (
            <FormErrorSubmit marginTop={3}>
              Signup link was invalid. Please go back to the{' '}
              <Link.para to={getRoute('signup', {})}>signup</Link.para> page to generate a new link.
            </FormErrorSubmit>
          )}

          {loading && (
            <StatusFeedback.info testID="signup-loading-message">
              Processing signup, please wait...
            </StatusFeedback.info>
          )}
        </Layout.VStack>

        <Layout.VStack space={2}>
          <Button.primaryMedium
            isDisabled={loading}
            isLoading={loading}
            testID="button-signup-password-submit"
            onPress={submitForm}
          >
            Continue
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
