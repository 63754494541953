import { IModalProps, IOverlayProps, Modal as NativeModal } from 'native-base';
import React, { ReactNode } from 'react';
import { Platform } from 'react-native';
import { FullStyledProps } from '../../styles/stylesheet';
import type { InterfaceBoxProps } from '../../types/nativeBaseTypes';

export { Modal } from 'native-base';

type SimpleModalProps = InterfaceBoxProps & {
  children: ReactNode;
  footer?: ReactNode;
  footerStyles?: FullStyledProps;
  onClose?: () => void;
  size?: IModalProps['size'];
};

export const SimpleModal = ({
  children,
  footer,
  footerStyles,
  onClose,
  size,
  testID,
  ...contentStyles
}: SimpleModalProps): JSX.Element => {
  const overlayStyles = {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
  };

  return (
    <NativeModal
      _overlay={{ style: overlayStyles as IOverlayProps['style'] }}
      closeOnOverlayClick={false}
      isOpen={true}
      onClose={onClose}
      size={size}
      testID={testID}
      safeAreaTop
    >
      <NativeModal.Content {...contentStyles}>
        <NativeModal.CloseButton />

        <NativeModal.Body>{children}</NativeModal.Body>

        {footer !== undefined && (
          <NativeModal.Footer {...footerStyles}>{footer}</NativeModal.Footer>
        )}
      </NativeModal.Content>
    </NativeModal>
  );
};
