import { SliceZone } from '@prismicio/react';
import { Heading, Layout, Skeleton } from '../../../components/core';
import { getStylesheet } from '../../../styles';
import { useViewByType } from '../hooks/useViewByType';
import { components as sliceComponents } from './slice';

type ViewProps = {
  hideHeader?: boolean;
  isCollection: boolean;
  omitModuleId?: string;
};

export const View = ({ hideHeader, isCollection, omitModuleId }: ViewProps): JSX.Element => {
  const [{ aggregatedSlice, modulesById, viewData }, { loading, error }] = useViewByType(
    isCollection ? 'collections' : 'topics',
  );

  if (!viewData || loading || error) {
    return (
      <Layout.View>
        <Layout.VStack space={4}>
          {loading ? (
            <Skeleton backgroundColor="white" height={148} />
          ) : (
            <Heading.h4>Could not load</Heading.h4>
          )}
        </Layout.VStack>
      </Layout.View>
    );
  }

  const { title } = viewData;
  const shouldHideHeader = Boolean(hideHeader);

  const viewContext = {
    isCollection,
    modulesById,
    omitModuleId,
  };

  return (
    <Layout.View>
      <Layout.VStack space={4}>
        {!shouldHideHeader && (
          <Layout.VStack {...styles.text} space={4}>
            {title && <Heading.h4>{title}</Heading.h4>}
          </Layout.VStack>
        )}

        {aggregatedSlice && (
          <SliceZone
            context={viewContext}
            components={sliceComponents}
            slices={[aggregatedSlice]}
          />
        )}
      </Layout.VStack>
    </Layout.View>
  );
};

const styles = getStylesheet({
  text: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    flex: 1,
    width: '100%',
  },
});
