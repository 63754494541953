import { JSX, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import { FormErrorSubmit, FormInput } from '../../../components/form';
import { useSendPasswordResetEmailMutation } from '../../../graphQL';
import { isRateLimited } from '../../../utils/graphql';
import { ResetPasswordRateLimitError } from '../components/ResetPasswordRateLimitError';

type ResetPasswordFormProps = {
  onEmailSent: () => void;
};
export const ResetPasswordForm = ({ onEmailSent }: ResetPasswordFormProps): JSX.Element => {
  const formContext = useForm({
    defaultValues: {
      email: '',
      submit: undefined,
    },
  });

  const [showResetPasswordRateLimitError, setShowResetPasswordRateLimitError] = useState(false);

  const [sendPasswordReset, { loading }] = useSendPasswordResetEmailMutation({
    onCompleted: (): void => {
      onEmailSent();
    },
    onError: authError => {
      if (isRateLimited(authError)) {
        setShowResetPasswordRateLimitError(true);
        return;
      }

      formContext.setError('submit', authError);
    },
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
  } = formContext;

  const submitForm = (): void => {
    // Make sure to clear the errors first, or any submit errors will prevent form submission.
    clearErrors();
    setShowResetPasswordRateLimitError(false);

    void handleSubmit(async ({ email }): Promise<void> => {
      await sendPasswordReset({ variables: { email } });
    })();
  };

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={6}>
        <Layout.View>
          <FormInput
            label="Email address"
            name="email"
            placeholder="School email address"
            autoCapitalize="none"
            autoFocus
            isRequired
            rules={{ pattern: { value: /.+@.+\..+/, message: 'You must enter a valid email.' } }}
            control={control}
            error={errors.email}
            keyboardType="email-address"
            onSubmitEditing={submitForm}
          />

          {showResetPasswordRateLimitError && (
            <Layout.View marginTop={6}>
              <ResetPasswordRateLimitError />
            </Layout.View>
          )}

          {errors.submit && (
            <FormErrorSubmit marginTop={6}>{errors.submit.message}</FormErrorSubmit>
          )}
        </Layout.View>

        <Layout.VStack>
          <Button.primaryMedium
            isDisabled={loading}
            testID="button-reset-password-submit"
            onPress={submitForm}
          >
            Send
          </Button.primaryMedium>
        </Layout.VStack>
      </Layout.VStack>
    </FormProvider>
  );
};
