import { merge } from 'lodash';
import React, { ReactNode } from 'react';
import { getStylesheet } from '../../styles';
import { IconType } from '../icons';
import { Pressable, PressableProps } from './Pressable';
import { Heading, Layout } from './index';
import type { StyledProps } from '../../types/nativeBaseTypes';

type VariantName = keyof typeof variantStyles;
type Variant = (typeof variantStyles)[VariantName];

const chipFactory =
  (styles: { variant: VariantName }): typeof Chip =>
  ({ children, ...props }) => {
    return (
      <Chip {...styles} {...props}>
        {children}
      </Chip>
    );
  };

/*
 * Variants
 */

export const primarySmall = chipFactory({
  variant: 'primary',
});

/*
 * Chip Component
 */

type ChipProps = StyledProps &
  Pick<PressableProps, 'onPress' | 'testID'> & {
    children: ReactNode;
    LeftIcon?: IconType;
    RightIcon?: IconType;
    isSelected?: boolean;
    variant?: VariantName;
  };

export const Chip = ({
  children,
  LeftIcon,
  RightIcon,
  onPress,
  testID,
  isSelected = false,
  variant,
}: ChipProps): JSX.Element => {
  const variantStyle: Variant = variantStyles[variant ?? 'primary'];
  const finalStyles = merge({}, styles, variantStyle, isSelected ? variantStyle._selected : {});

  return (
    <Pressable
      {...finalStyles.pressable}
      accessibilityRole="button"
      testID={testID}
      onPress={onPress}
    >
      <Layout.HStack {...finalStyles.topStack} space={3}>
        {LeftIcon !== undefined && <LeftIcon {...finalStyles.icon} />}

        <Layout.Box {...finalStyles.textBox}>
          <Heading.h6 {...finalStyles.text} textAlign={LeftIcon ? 'left' : 'center'}>
            {children}
          </Heading.h6>
        </Layout.Box>

        {RightIcon !== undefined && <RightIcon {...finalStyles.icon} />}
      </Layout.HStack>
    </Pressable>
  );
};

const styles = getStylesheet({
  pressable: {
    flex: 1,
    justifyContent: 'center',
    padding: 3,
  },

  topStack: {
    alignItems: 'center',
  },

  icon: {},

  textBox: {
    flex: 1,
  },

  text: {},
});

const variantStyles = {
  primary: {
    pressable: {
      backgroundColor: 'primary.alpha.20:alpha.20',
      borderRadius: 20,
    },

    icon: {
      color: 'secondary.900',
    },

    text: {
      color: 'secondary.900',
    },

    _selected: {
      pressable: {
        backgroundColor: 'secondary.900',
      },

      icon: {
        color: 'white',
      },

      text: {
        color: 'white',
      },
    },
  },
};
