import { JSX, useEffect, useMemo, useState } from 'react';
import { Heading, Layout, Progress } from '../../../components/core';
import { ConfirmLeaveModal } from '../../../components/modals/ConfirmLeaveModal';
import { PageContent, PageTopBar } from '../../../components/page';
import { useScrollContext } from '../../../contexts/scrollContext';
import { getRoute, useCurrentRouteName, useNavigate, useParams } from '../../../routes';
import { getStylesheet } from '../../../styles';
import { useFindCareSlideContext } from '../context/findCareSlideContext';
import {
  findCareTotalPages,
  getFindCareCurrentPageIndex,
  getFindCarePreviousNavRoute,
  getFindCarePreviousPageCount,
} from '../findCareRoutes';

type FindCarePageWrapperProps = {
  children: JSX.Element;
  testID: string;
  showProgress?: boolean;
  onBackPress?: () => void;
  showGap?: boolean;
};

export const FindCarePageWrapper = ({
  children,
  testID,
  showProgress = true,
  onBackPress,
  showGap = true,
}: FindCarePageWrapperProps): JSX.Element => {
  const routeName = useCurrentRouteName();
  const navigate = useNavigate();
  const params = useParams();

  const { triggerPrevSlide, slideNumber, slideCount } = useFindCareSlideContext();
  const { scrollToTop } = useScrollContext();

  useEffect(() => {
    // When the page loads, make sure to scroll to the top.
    scrollToTop();
  }, []);

  const [showCloseModal, setShowCloseModal] = useState(false);

  const currentPageIndex = useMemo(() => {
    return getFindCareCurrentPageIndex(routeName, params);
  }, [routeName, params]);

  const currentProgress = useMemo(() => {
    const previousPages = getFindCarePreviousPageCount(currentPageIndex);

    return ((previousPages + 1) / findCareTotalPages) * 100;
  }, [currentPageIndex]);

  const goBack = (): void => {
    if (onBackPress !== undefined) {
      onBackPress();
      return;
    }

    if (slideCount > 0 && slideNumber > 1) {
      triggerPrevSlide();
      return;
    }

    const backRoute = getFindCarePreviousNavRoute(currentPageIndex);
    if (backRoute !== undefined) {
      navigate(backRoute);
      return;
    }

    // Show the modal to confirm navigating back to the home page.
    setShowCloseModal(true);
  };

  const goHome = (): void => {
    navigate(getRoute('home', {}));
  };

  const containerStyle = showGap
    ? { ...styles.container, ...styles.containerGap }
    : styles.container;

  return (
    <PageContent {...containerStyle} testID={testID}>
      <Layout.Box>
        <PageTopBar
          backAccessibilityLabel="Navigate to the previous page."
          onBackPress={goBack}
          onClosePress={() => setShowCloseModal(true)}
        >
          <Heading.h5 center paddingY={3}>
            Find Care
          </Heading.h5>
        </PageTopBar>

        {showProgress && (
          <Progress
            {...styles.progress}
            accessibilityLabel="Find care progress."
            variant="skill"
            value={currentProgress}
          />
        )}

        {showCloseModal && (
          <ConfirmLeaveModal onClose={() => setShowCloseModal(false)} onConfirm={goHome} />
        )}
      </Layout.Box>
      {children}
    </PageContent>
  );
};

const styles = getStylesheet({
  container: {
    flex: 1,
    padding: 1,
  },

  containerGap: {
    gap: 8,
  },

  progress: {
    alignSelf: 'center',
    marginBottom: 4,
    width: '70%',
  },
});
