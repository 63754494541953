import { RecommendationOptions, useCareRecommendationGetForUserQuery } from '../../../graphQL';

export type CareRecommendationOutputs = {
  careRecommendation: RecommendationOptions | undefined;
  onDemandEnabled: boolean;
  connectNowEnabled: boolean;
  togetherAllEnabled: boolean;
};

type UseCareRecommendationReturn = [
  CareRecommendationOutputs,
  {
    loading: boolean;
    loaded: boolean;
    error?: Error;
  },
];

export const useCareRecommendation = (): UseCareRecommendationReturn => {
  const {
    data: careRecommendationData,
    loading: careRecommendationLoading,
    error: careRecommendationError,
  } = useCareRecommendationGetForUserQuery();

  const { careRecommendation, onDemandEnabled, connectNowEnabled, togetherAllEnabled } =
    careRecommendationData?.careRecommendationGetForUser ?? {
      careRecommendation: undefined,
      connectNowEnabled: false,
      onDemandEnabled: false,
      togetherAllEnabled: false,
    };

  return [
    {
      careRecommendation: careRecommendation ?? undefined,
      connectNowEnabled,
      onDemandEnabled,
      togetherAllEnabled,
    },
    {
      loading: careRecommendationLoading,
      loaded: !careRecommendationLoading,
      error: careRecommendationError,
    },
  ];
};
