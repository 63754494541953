import { JSX } from 'react';
import { useNavigate } from 'react-router-native';
import { StatusFeedback, Text } from '../../../components/core';
import { IconMessageSquare } from '../../../components/icons/IconMessageSquare';
import { useUnreadHubExternalMessagesQuery } from '../../../graphQL';
import { getRoute } from '../../../routes';

export const UnreadExternalMessagesWidget = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { data, loading, error } = useUnreadHubExternalMessagesQuery();

  if (loading || error !== undefined || data?.unreadHubExternalMessages == null) {
    return null;
  }

  const firstMessageCount = data.unreadHubExternalMessages[0];
  if (firstMessageCount === undefined || firstMessageCount.totalCount < 1) {
    return null;
  }

  const onViewPress = (): void => {
    navigate(getRoute('patientPortal', {}, { mrn: firstMessageCount.mrn }));
  };

  const plural = firstMessageCount.totalCount === 1 ? '' : 's';

  return (
    <StatusFeedback.info
      buttonText="View"
      Icon={IconMessageSquare}
      onPress={onViewPress}
      testID="unread-external-messages-widget"
    >
      <Text.para bold>
        {firstMessageCount.totalCount} unread message{plural}
      </Text.para>
    </StatusFeedback.info>
  );
};
