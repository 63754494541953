import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Layout } from '../../../components/core';
import {
  FormChips,
  FormInput,
  FormInputMasked,
  FormSelect,
  FormTextArea,
} from '../../../components/form';
import { useFormScrolling } from '../../../components/form/hooks/useFormScrolling';
import { PageError } from '../../../components/page';
import {
  ConnectNowAlternativeCare,
  ConnectNowMainConcerns,
  ConnectNowSuicidalThoughts,
  useCreateConnectNowPreEncounterMutation,
} from '../../../graphQL';
import { getRoute, useNavigate } from '../../../routes';

const PHONE_FORMAT = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

/**
 * Keep in sync with api/src/models/connectNow/connectNowPreEncounter.entity.ts
 * ConnectNowMainConcernsEnum
 */
const mainConcernOptions = {
  SuicidalThoughts:
    '\u0049\u0027\u006d\u0020\u0068\u0061\u0076\u0069\u006e\u0067\u0020\u0073\u0075\u0069\u0063\u0069\u0064\u0061\u006c\u0020\u0074\u0068\u006f\u0075\u0067\u0068\u0074\u0073',
  ConcernForSomeoneElse: "I'm concerned for someone else",
  Academics: 'Academics',
  AnxietyStress: 'Anxiety / Stress',
  CareerPlanning: 'Career Planning / Job Search',
  Depression: 'Depression',
  Family: 'Family',
  FeelingDown: 'Feeling Down',
  Finances: 'Finances',
  FriendsRoommates: 'Friends / Roommates',
  Grief: 'Grief / Bereavement',
  HealthConcerns: 'Health Concerns',
  LGBTQIA: 'LGBTQIA+',
  LifeChanges: 'Life Changes',
  Loneliness: 'Loneliness / Isolation',
  Relationship: 'Relationship',
  SubstanceAbuse:
    '\u0053\u0075\u0062\u0073\u0074\u0061\u006e\u0063\u0065\u0020\u0041\u0062\u0075\u0073\u0065',
  SelfInjury: '\u0053\u0065\u006c\u0066\u002d\u0049\u006e\u006a\u0075\u0072\u0079',
  Work: 'Work',
  Other: 'Other (fill in)',
} as const;

const distressLevelOptions = {
  '10': '10 - Extreme distress',
  '9': '9',
  '8': '8',
  '7': '7',
  '6': '6',
  '5': '5',
  '4': '4',
  '3': '3',
  '2': '2',
  '1': '1',
  '0': '0 - No distress',
} as const;

const alternativesOptions = {
  noSupport: 'Would not have sought support',
  collegeCounselor: 'College counseling center',
  mantraTherapy: 'Mantra therapy visit',
  offCampusTherapy: 'Off-campus therapy (community based or online)',
  campusCrisisLine: 'Campus specific crisis line',
  otherOr998: '988 or other non-campus crisis resource',
  emergencyRoom: 'Emergency room/hospital',
  unsure: 'Unsure',
  other: 'Another option not listed here (fill in)',
} as const;

/**
 * Keep properties in sync with api/src/models/connectNow/connectNowPreEncounter.entity.ts
 * ConnectNowSuicidalThoughtsEnum
 */
const thoughtsOptions = {
  WithinThePastMonth: 'Yes, within the past month',
  WithinThePastYear: 'Yes, within the past year',
  DuringMyLifeTime: 'Yes, during my lifetime, but not in the past year',
  Never: 'No, I have never had these thoughts',
} as const;

type FormValues = {
  mainConcern: string;
  otherMainConcern: string;
  distressLevel: string;
  thoughts: string;
  currentLocation: string;
  preferredPhoneNumber: string;
  alternativeCare: string;
  otherAlternativeCare: string;
  useSavedPreferredPhoneNumber: string;
};

type PreEncounterFormProps = {
  currentPhone: string;
};

export const PreEncounterForm = ({ currentPhone }: PreEncounterFormProps): JSX.Element => {
  const formContext = useForm<FormValues>({
    defaultValues: {
      mainConcern: '',
      otherMainConcern: '',
      distressLevel: '',
      thoughts: '',
      currentLocation: '',
      preferredPhoneNumber: '',
      alternativeCare: '',
      otherAlternativeCare: '',
      useSavedPreferredPhoneNumber: currentPhone === '' ? 'false' : 'true',
    },
  });

  const navigate = useNavigate();
  const [pageError, setPageError] = React.useState<boolean>(false);
  const [createConnectNowPreEncounter, refetch] = useCreateConnectNowPreEncounterMutation({
    onCompleted: () => {
      navigate(getRoute('connectNowJoinCall', {}));
    },
    onError: () => {
      setPageError(true);
    },
  });
  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = formContext;

  const { onLayout, scrollToFirstError } = useFormScrolling<FormValues>(errors);

  const mainConcernValue = watch('mainConcern');
  const useSavedPreferredPhoneNumber = watch('useSavedPreferredPhoneNumber');
  const mainConcernShowOther = mainConcernValue === ConnectNowMainConcerns.Other;
  const alternativesShowOther = watch('alternativeCare') === 'other';

  const submitForm = async (): Promise<void> => {
    // Scroll to the first error if there are any.
    scrollToFirstError();

    clearErrors();

    await handleSubmit(async (values: FormValues): Promise<void> => {
      await createConnectNowPreEncounter({
        variables: {
          data: {
            mainConcern: values.mainConcern as ConnectNowMainConcerns,
            ...(mainConcernShowOther ? { otherMainConcern: values.otherMainConcern } : {}),
            distressLevel: Number(values.distressLevel),
            suicidalThoughts: values.thoughts as ConnectNowSuicidalThoughts,
            currentLocation: values.currentLocation,
            preferredPhoneNumber:
              values.useSavedPreferredPhoneNumber === 'true'
                ? currentPhone
                : values.preferredPhoneNumber,
            alternativeCare: values.alternativeCare as ConnectNowAlternativeCare,
            ...(alternativesShowOther ? { otherAlternativeCare: values.otherAlternativeCare } : {}),
          },
        },
      });
    })();
  };

  if (pageError) {
    return <PageError showContact onRefreshPress={() => refetch} />;
  }

  return (
    <FormProvider {...formContext}>
      <Layout.VStack space={10}>
        <FormSelect
          name="mainConcern"
          onLayout={onLayout('mainConcern')}
          options={mainConcernOptions}
          placeholder="Select One"
          control={control}
          error={errors.mainConcern}
          label="What is your main concern?"
          isRequired
          necessityIndicator
        />

        {mainConcernShowOther && (
          <FormTextArea
            name="otherMainConcern"
            onLayout={onLayout('otherMainConcern')}
            placeholder="What's going on?"
            label="Briefly describe your main concern today."
            minHeight="120px"
            control={control}
            error={errors.otherMainConcern}
            isRequired
            necessityIndicator
          />
        )}

        <FormSelect
          name="distressLevel"
          onLayout={onLayout('distressLevel')}
          options={distressLevelOptions}
          placeholder="Select One"
          control={control}
          error={errors.distressLevel}
          label="On a scale of 0-10, how would you rate the level of distress you are experiencing right now? (0 = no distress; 10 = extreme distress)"
          isRequired
          necessityIndicator
        />

        <FormSelect
          name="thoughts"
          onLayout={onLayout('thoughts')}
          options={thoughtsOptions}
          placeholder="Select One"
          control={control}
          error={errors.thoughts}
          label={
            'Have you ever \u0068\u0061\u0064\u0020\u0074\u0068\u006f\u0075\u0067\u0068\u0074\u0073\u0020\u006f\u0066\u0020\u006b\u0069\u006c\u006c\u0069\u006e\u0067\u0020\u0079\u006f\u0075\u0072\u0073\u0065\u006c\u0066?'
          }
          isRequired
          necessityIndicator
        />

        <FormSelect
          name="alternativeCare"
          onLayout={onLayout('alternativeCare')}
          options={alternativesOptions}
          placeholder="Select One"
          control={control}
          error={errors.alternativeCare}
          label="Where would you have sought support today if ConnectNow was not offered?"
          isRequired
          necessityIndicator
        />

        {alternativesShowOther && (
          <FormInput
            name="otherAlternativeCare"
            onLayout={onLayout('otherAlternativeCare')}
            placeholder="Alternatives"
            label="Describe Other Option(s) for Support"
            control={control}
            error={errors.otherAlternativeCare}
            rules={{ minLength: 5 }}
          />
        )}

        <FormInput
          name="currentLocation"
          onLayout={onLayout('currentLocation')}
          placeholder="Room 154, Campus Hall, Campus College"
          label="Provide your current location"
          helperText="Your current location is required for an ConnectNow video session with Mantra Health and
            will only be used by your provider to assist you in the event of an emergency."
          rules={{
            minLength: {
              value: 5,
              message: 'You must enter your current location (min 5 characters).',
            },
          }}
          control={control}
          error={errors.currentLocation}
          isRequired
          necessityIndicator
        />

        <Layout.VStack space={4}>
          {currentPhone !== '' && (
            <FormChips
              name="useSavedPreferredPhoneNumber"
              onLayout={onLayout('useSavedPreferredPhoneNumber')}
              label={`If your call gets disconnected, is your saved phone number, ${currentPhone}, the best way to reach you?`}
              options={{
                true: 'Yes, use my saved number',
                false: 'No, please use a different number',
              }}
              error={errors.useSavedPreferredPhoneNumber}
              isRequired
              necessityIndicator
            />
          )}

          {useSavedPreferredPhoneNumber === 'false' && (
            <FormInputMasked
              name="preferredPhoneNumber"
              onLayout={onLayout('preferredPhoneNumber')}
              placeholder="603-989-9989"
              label="Preferred phone number"
              helperText="Provide a number where we can reach you if your call gets disconnected."
              control={control}
              error={errors.preferredPhoneNumber}
              mask={PHONE_FORMAT}
              rules={{ minLength: 12 }}
              isRequired
              necessityIndicator
              inputMode="tel"
            />
          )}
        </Layout.VStack>

        <Button.primaryLarge testID="button-preencounter-form-submit" onPress={submitForm}>
          Submit
        </Button.primaryLarge>
      </Layout.VStack>
    </FormProvider>
  );
};
