import { castArray, compact, includes, map, uniq } from 'lodash';
import { FormControl } from 'native-base';
import { JSX } from 'react';
import { Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { Chip, Layout, Text } from '../core';
import { FormErrorInput } from './FormErrorInput';
import { FormLabel } from './FormLabel';
import { FormScrollingProps } from './hooks/useFormScrolling';

type FormChipsProps<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> &
  FormScrollingProps<TFieldValues> & {
    direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
    error?: FieldErrors<TFieldValues>[string];
    label?: string;
    isMulti?: boolean;
    isRequired?: boolean;
    necessityIndicator?: boolean;
    options: Record<string, string>;
    space?: number;
  };

export function FormChips<TFieldValues extends FieldValues>({
  direction = 'column',
  control,
  error,
  label = '',
  isMulti = false,
  isRequired = false,
  name,
  necessityIndicator = false,
  onLayout,
  options,
  space = 2,
}: FormChipsProps<TFieldValues>): JSX.Element {
  const finalRules = {
    required: {
      value: Boolean(isRequired),
      message: 'This field is required.',
    },
  };

  const nativeId = name;

  return (
    <FormControl isInvalid={Boolean(error?.type)} nativeID={nativeId} onLayout={onLayout}>
      <FormLabel label={label} necessityIndicator={necessityIndicator} isRequired={isRequired} />

      {isMulti && <Text.paraSmall marginBottom={4}>Select all that apply</Text.paraSmall>}

      <Controller
        name={name}
        control={control}
        rules={finalRules}
        render={({ field: { onChange, value } }) => {
          const currentValues: string[] = castArray(value);

          const onOptionPress = (optionValue: string) => () => {
            if (isMulti) {
              let newValues: string[];

              if (includes(currentValues, optionValue)) {
                newValues = currentValues.filter(currentValue => currentValue !== optionValue);
              } else {
                newValues = uniq([...currentValues, optionValue]);
              }

              onChange(compact(newValues));
              return;
            }

            if (isRequired) {
              onChange(optionValue);
              return;
            }

            onChange(value === optionValue ? '' : optionValue);
          };

          return (
            <Layout.Stack direction={direction} space={space}>
              {map(options, (optionLabel, optionValue) => {
                return (
                  <Chip.primarySmall
                    key={optionValue}
                    isSelected={includes(currentValues, optionValue)}
                    testID={`chip-form-chips-${optionValue}`}
                    onPress={onOptionPress(optionValue)}
                  >
                    {optionLabel}
                  </Chip.primarySmall>
                );
              })}
            </Layout.Stack>
          );
        }}
      />

      {error && <FormErrorInput nativeID={nativeId}>{error.message}</FormErrorInput>}
    </FormControl>
  );
}
