import { defaultTo, trim } from 'lodash';
import { useToast } from 'native-base';
import { useState } from 'react';
import { Keyboard } from 'react-native';
import { Heading, Layout, Link, Text, TextArea } from '../../../../../components/core';
import { IconBook } from '../../../../../components/icons/IconBook';
import { ConfirmLeaveModal } from '../../../../../components/modals/ConfirmLeaveModal';
import { RichText } from '../../../../../components/prismic';
import { usePortalContext } from '../../../../../contexts/portalContext';
import { useAnswerSkillReflectionQuestionMutation } from '../../../../../graphQL';
import { getRoute, useNavigate } from '../../../../../routes';
import { getStylesheet } from '../../../../../styles';
import { Portals } from '../../../../portal/portals';
import { ReflectionSlideSlice } from '../../../SkillTypes';

import { SkillWrapper } from '../../SkillWrapper';
import type { SliceContext } from '../../skillTypes';

type ReflectionSlideProps = {
  context: SliceContext;
  slice: ReflectionSlideSlice;
};

export const ReflectionSlide = ({ context, slice }: ReflectionSlideProps): JSX.Element => {
  const navigate = useNavigate();
  const { onlyIfPortalIsActive } = usePortalContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const mainItem = slice.primary;
  const questionKey = trim(defaultTo(mainItem.question_key, ''));
  const questionText = trim(defaultTo(mainItem.question, ''));

  const reflectionQuestion = context.questions?.find(
    existingQuestion => existingQuestion.questionKey === questionKey,
  );

  const coachingEnabled = onlyIfPortalIsActive(Portals.Coaching, true, false);

  const toast = useToast();
  const [answerValue, setAnswerValue] = useState(reflectionQuestion?.answer ?? '');

  const [submitAnswer] = useAnswerSkillReflectionQuestionMutation({
    onError: () => {
      toast.show({
        description: 'Response could not be saved',
        duration: 3000,
      });
    },
    onCompleted: ({ answerSkillReflectionQuestion }) => {
      if (trim(answerValue) === '') {
        // If the answer was not updated but there is no answer, don't display a toast at all.
        // This means the user has never answered the question.
        return;
      }

      if (!answerSkillReflectionQuestion) {
        toast.show({
          description: 'Response could not be saved',
          duration: 3000,
        });
        return;
      }

      toast.show({
        description: 'Response saved',
        duration: 3000,
      });
    },
  });

  const onContinue = (): void => {
    Keyboard.dismiss();

    const trimmedValue = trim(answerValue);

    setAnswerValue(trimmedValue);

    if (!questionKey) {
      return;
    }

    void submitAnswer({
      variables: {
        skillId: context.skillId,
        questionKey,
        question: questionText,
        answer: trimmedValue,
      },
    });
  };

  const onChangeText = (text: string): void => {
    if (text.length > 4040) {
      toast.show({
        description: 'Maximum length of 4040 characters reached.',
        duration: 3000,
      });
      return;
    }

    setAnswerValue(text);
  };

  const onCloseConfirmModalPress = (): void => {
    setShowConfirmModal(false);
  };

  const onCrisisResourcesLeaveConfirmModalPress = (): void => {
    navigate(getRoute('crisisResources', {}));
  };

  return (
    <SkillWrapper onContinue={onContinue}>
      {showConfirmModal && (
        <ConfirmLeaveModal
          onClose={onCloseConfirmModalPress}
          onConfirm={onCrisisResourcesLeaveConfirmModalPress}
        />
      )}
      <Layout.VStack space={4}>
        <Layout.HStack {...styles.reflection} space={2}>
          <IconBook size={4} />
          <Heading.h6>Reflection</Heading.h6>
        </Layout.HStack>

        <Heading.h2>{questionText}</Heading.h2>

        {mainItem.description !== undefined && <RichText field={mainItem.description} />}

        <Layout.VStack space={2}>
          <Text.para bold>Enter your response</Text.para>

          <TextArea
            autoCompleteType={undefined}
            backgroundColor="white:alpha.60"
            fontSize="md"
            h={140}
            placeholder={mainItem.placeholder ?? undefined}
            placeholderTextColor="secondary.700"
            onChangeText={onChangeText}
            value={answerValue}
            testID="reflection-text-area"
          />

          <Text.caption color="secondary.alpha.80:alpha.80">
            {coachingEnabled
              ? 'Note: Your responses are visible to your coach, but they are not monitored in real-time. If you are in need of immediate assistance, please visit our'
              : 'Note: Your responses are confidential and will not be reviewed for safety purposes or crisis response. If you need immediate support, please visit these'}{' '}
            <Link.caption
              onPress={() => setShowConfirmModal(true)}
              to={getRoute('crisisResources', {})}
            >
              crisis resources.
            </Link.caption>
          </Text.caption>
        </Layout.VStack>
      </Layout.VStack>
    </SkillWrapper>
  );
};

export default ReflectionSlide;

const styles = getStylesheet({
  reflection: {
    display: 'flex',
    alignItems: 'center',
  },
});
