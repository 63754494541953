import { JSX, useState } from 'react';
import { Heading, Layout, Pressable, Text } from '../../../components/core';
import { IconChevronRight } from '../../../components/icons/IconChevronRight';
import { getStylesheet } from '../../../styles';
import { ImageFriendReferral } from './ImageFriendReferral';
import { PostStudentReferralModal } from './PostStudentReferralModal';
import { StudentReferralModal } from './StudentReferralModal';

type StudentReferralCardProps = {
  orgName: string;
};

export const StudentReferralCard = ({ orgName }: StudentReferralCardProps): JSX.Element => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showPostReferralModal, setShowPostReferralModal] = useState(false);

  return (
    <Layout.Flex>
      <Pressable
        {...styles.studentReferral}
        onPress={() => setShowReferralModal(true)}
        testID="pressable-show-student-referral-modal"
        accessibilityLabel="Open referral modal"
      >
        <Layout.HStack {...styles.contentWrapper}>
          <Layout.HStack {...styles.leftContent}>
            <Layout.VStack {...styles.imageWrapper}>
              <ImageFriendReferral size="100px" />
            </Layout.VStack>

            <Layout.VStack {...styles.textContent} space={2}>
              <Heading.h4>Refer a friend</Heading.h4>

              <Text.paraSmall>
                Sharing is caring! Send the Care Hub to a friend or classmate.
              </Text.paraSmall>
            </Layout.VStack>
          </Layout.HStack>

          <Layout.VStack {...styles.rightContent}>
            <IconChevronRight size={6} />
          </Layout.VStack>
        </Layout.HStack>
      </Pressable>

      {showReferralModal && (
        <StudentReferralModal
          onClose={() => {
            setShowReferralModal(false);
          }}
          onReferralSubmit={() => {
            setShowReferralModal(false);
            setShowPostReferralModal(true);
          }}
          orgName={orgName}
        />
      )}

      {showPostReferralModal && (
        <PostStudentReferralModal
          onClose={() => {
            setShowPostReferralModal(false);
          }}
        />
      )}
    </Layout.Flex>
  );
};

const styles = getStylesheet({
  contentWrapper: {
    justifyContent: 'space-between',
  },

  studentReferral: {
    backgroundColor: 'primary.200',
    borderRadius: 4,
  },

  imageWrapper: {
    paddingTop: 3,
  },

  leftContent: {
    flex: 1,
  },

  rightContent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
  },

  textContent: {
    justifyContent: 'center',
    flex: 1,
  },
});
