import { Button, Heading, Layout, SimpleModal, Text } from '../../../components/core';
import { getStylesheet } from '../../../styles';

export const PostStudentReferralModal = ({ onClose }: { onClose: () => void }): JSX.Element => {
  return (
    <SimpleModal {...styles.modal} onClose={onClose}>
      <Layout.VStack space={6} {...styles.modalContent}>
        <Layout.VStack space={2} {...styles.textContent}>
          <Heading.h2>Referral sent.</Heading.h2>

          <Text.para>
            If eligible, the person you referred will receive an email with more information.
          </Text.para>
        </Layout.VStack>

        <Button.primaryMedium
          {...styles.button}
          testID="button-confirm-student-referral-submit"
          onPress={onClose}
        >
          OK
        </Button.primaryMedium>
      </Layout.VStack>
    </SimpleModal>
  );
};

const styles = getStylesheet({
  button: {
    alignSelf: 'flex-start',
  },

  modal: {
    borderRadius: 0,
  },

  modalContent: {
    paddingX: 4,
    paddingY: 4,
  },

  textContent: {
    paddingY: 6,
  },
});
