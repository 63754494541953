import { useState } from 'react';
import { StatusFeedback, Text } from '../../../components/core';
import { IconAlertTriangle } from '../../../components/icons/IconAlertTriangle';
import { ConfirmLeaveModal } from '../../../components/modals/ConfirmLeaveModal';
import { useBreakpointSwitch } from '../../../contexts/breakpointContext';
import { usePortalContext } from '../../../contexts/portalContext';
import { getRoute } from '../../../routes';
import { Portals } from '../../portal/portals';
import { usePortalNavigate } from '../../portal/usePortalNavigate';

export const InputMonitorWarning = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const portalNavigate = usePortalNavigate();

  const { onlyIfPortalIsActive } = usePortalContext();
  const connectNowActive = onlyIfPortalIsActive(Portals.ConnectNow, true, false);

  const [verticalFeedback] = useBreakpointSwitch({
    mobile: [true],
    desktop: [false],
  });

  const onConfirm = (): void => {
    if (connectNowActive) {
      portalNavigate(
        Portals.ConnectNow,
        getRoute('connectNowHome', {}),
        getRoute('crisisResources', {}),
      );
    } else {
      portalNavigate(Portals.Odes, getRoute('onDemandHome', {}), getRoute('crisisResources', {}));
    }
  };

  const serviceName = connectNowActive ? 'ConnectNow' : 'On-Demand Emotional Support';

  return (
    <>
      <StatusFeedback.warning
        testID="input-monitor-warning"
        Icon={IconAlertTriangle}
        buttonText={`Visit ${serviceName}`}
        onPress={() => setShowModal(true)}
        vertical={verticalFeedback}
      >
        <Text.paraSmall bold>Please note:</Text.paraSmall> Answers to this form are not monitored in
        real time. If you need immediate support, use {serviceName} or a provided crisis resource.
      </StatusFeedback.warning>

      {showModal && <ConfirmLeaveModal onClose={() => setShowModal(false)} onConfirm={onConfirm} />}
    </>
  );
};
